import { AppContext } from "../../typings";

const getBookingsByCustomer = async (
  context: AppContext,
): Promise<string | Error> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const body = JSON.stringify({
    token: context.token,
    email: context.customer.profile.email,
  });

  const options = {
    method: "POST",
    headers: headers,
    body,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/bookings-by-customer`,
      options,
    ).then((r) => r.json());

    if (
      response.data &&
      response.data instanceof Array &&
      response.data.length > 0
    ) {
      return response.data;
    }

    throw response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export default getBookingsByCustomer;
