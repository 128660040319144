import { Card, Col, Divider, Row } from "antd";
import React, { ReactElement } from "react";
import * as dateFns from "date-fns";
import { FlightDetails } from "../../typings";
import sampleBookingDetailsMulticityInternational from "../../typings/sample-booking-details-multicity-international";
import sampleBookingDetailsRoundtripInternational from "../../typings/sample-booking-details-roundtrip-international";

const FlightSegment = ({
  flightSegment,
  index,
  total,
  pnr,
  segmentType,
}: FlightSegmentProps): ReactElement => {
  const departureDay = `0${flightSegment.departure_date.day}`.slice(-2);

  const departureMonth = `0${flightSegment.departure_date.month}`.slice(-2);

  const departureHour = `0${flightSegment.departure_time.hour}`.slice(-2);

  const departureMinute = `0${flightSegment.departure_time.minute}`.slice(-2);

  const departureDateTime = `${flightSegment.departure_date.year}-${departureMonth}-${departureDay}T${departureHour}:${departureMinute}`;

  const arrivalDay = `0${flightSegment.arrival_date.day}`.slice(-2);

  const arrivalMonth = `0${flightSegment.arrival_date.month}`.slice(-2);

  const arrivalHour = `0${flightSegment.arrival_time.hour}`.slice(-2);

  const arrivalMinute = `0${flightSegment.arrival_time.minute}`.slice(-2);

  const arrivalDateTime = `${flightSegment.arrival_date.year}-${arrivalMonth}-${arrivalDay}T${arrivalHour}:${arrivalMinute}`;

  const flightDurationHours =
    flightSegment.flight_duration / 60 >= 1
      ? `${Math.floor(flightSegment.flight_duration / 60)} ${
          Math.floor(flightSegment.flight_duration / 60) > 1 ? "hours" : "hour"
        }`
      : "";

  const flightDurationMinutes =
    flightSegment.flight_duration % 60 !== 0
      ? `${flightSegment.flight_duration % 60} minutes`
      : "";

  const flightDuration = `${flightDurationHours} ${flightDurationMinutes}`;

  let pnrSection = pnr;

  if (segmentType === "Departing") {
    [pnrSection] = pnr.split("-");
  } else if (segmentType === "Returning") {
    [, pnrSection] = pnr.split("-");
  }

  return (
    <Col>
      <Card
        className="flight-segment"
        title={`${pnrSection} - ${segmentType} Flight Segment (${
          index + 1
        }/${total})`}
      >
        <Row justify="center" align="middle">
          <Col>
            <Card>
              <div>
                Airline Name:{" "}
                <b>
                  {flightSegment.airline.airline_name || "N/A"} (
                  {flightSegment.airline.code})
                </b>
              </div>

              <div>
                Flight Number: <b>{flightSegment.airline.flight_number}</b>
              </div>

              <div>Flight Duration: {flightDuration}</div>

              <div>Cabin: {flightSegment.cabin || "N/A"}</div>

              <div>Aircraft: {flightSegment.aircraft || "N/A"}</div>
            </Card>
          </Col>
        </Row>

        <Card>
          <Row justify="center" align="middle">
            <Col>
              <Card>
                Departure Airport: <b>{flightSegment.departure_airport.code}</b>
              </Card>
              <Card>
                Arrival Airport: <b>{flightSegment.arrival_airport.code}</b>
              </Card>
            </Col>

            <Col>
              <Card>
                Departure:{" "}
                {dateFns.format(
                  dateFns.parseISO(`${departureDateTime}`),
                  "PPPPpppp",
                )}
              </Card>
              <Card>
                Arrival:{" "}
                {dateFns.format(
                  dateFns.parseISO(`${arrivalDateTime}`),
                  "PPPPpppp",
                )}
              </Card>
            </Col>
          </Row>
        </Card>
      </Card>
    </Col>
  );
};

interface Props {
  flightDetails: FlightDetails;
  pnr: string;
}

const FlightDetailsComponent = ({
  flightDetails,
  pnr,
}: Props): ReactElement => {
  if (
    (
      flightDetails as typeof sampleBookingDetailsMulticityInternational["flight_details"]
    )?.trips
  ) {
    return (
      <>
        {(
          flightDetails as typeof sampleBookingDetailsMulticityInternational["flight_details"]
        )?.trips.map((trip, i) => (
          <div key={Math.random()}>
            {i > 0 ? <Divider /> : ""}

            <Col span={24}>
              <FlightDetailsComponent
                flightDetails={trip}
                pnr={pnr.split("-")[i]}
              />
            </Col>
          </div>
        ))}
      </>
    );
  }

  if (
    (
      flightDetails as typeof sampleBookingDetailsMulticityInternational["flight_details"]["trips"][0]
    ).departing_flight_segments
  ) {
    const flightSegments = (
      flightDetails as typeof sampleBookingDetailsMulticityInternational["flight_details"]["trips"][0]
    ).departing_flight_segments;

    const returningFlightSegments = (
      flightDetails as typeof sampleBookingDetailsRoundtripInternational["flight_details"]
    ).returning_flight_segments;

    return (
      <>
        {flightSegments.map((segment, i) => {
          return (
            <div key={Math.random()}>
              {segment.transit_duration && (
                <Row>
                  <Col>
                    <Card title={`Flight ${i + 1} to Flight ${i + 2} Transit`}>
                      Transit Duration: {segment.transit_duration} minutes
                    </Card>
                  </Col>
                </Row>
              )}

              <FlightSegment
                key={Math.random()}
                flightSegment={segment}
                index={i}
                total={flightSegments.length}
                pnr={pnr}
                segmentType="Departing"
              />
            </div>
          );
        })}

        {returningFlightSegments &&
          returningFlightSegments instanceof Array &&
          returningFlightSegments.length > 0 &&
          returningFlightSegments.map((segment, i) => {
            return (
              <div key={Math.random()}>
                {segment.transit_duration && (
                  <Row>
                    <Col>
                      <Card
                        title={`Flight ${i + 1} to Flight ${i + 2} Transit`}
                      >
                        Transit Duration: {segment.transit_duration} minutes
                      </Card>
                    </Col>
                  </Row>
                )}

                <FlightSegment
                  key={Math.random()}
                  flightSegment={segment}
                  index={i}
                  total={flightSegments.length}
                  pnr={pnr}
                  segmentType="Returning"
                />
              </div>
            );
          })}
      </>
    );
  }

  return <></>;
};

interface FlightSegmentProps {
  flightSegment:
    | typeof sampleBookingDetailsMulticityInternational["flight_details"]["trips"][0]["departing_flight_segments"][0]
    | typeof sampleBookingDetailsRoundtripInternational["flight_details"]["returning_flight_segments"][0];
  index: number;
  total: number;
  pnr: string;
  segmentType: "Departing" | "Returning";
}

export default FlightDetailsComponent;
