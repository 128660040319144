import React from "react";
import { Card, Col, Form, Input, Row } from "antd";
import { TagTwoTone } from "@ant-design/icons";
import { green } from "@ant-design/colors";

import { Props, ValidationError } from "../../typings";

export default ({ currentState, sendEvent }: Props): React.ReactElement => {
  const [form] = Form.useForm();

  const onFinish = ({ token, pnr }: FormValues) => {
    let modifiedPNR = pnr.trim();

    const delimiters = [" ", ","];

    for (const sign of delimiters) {
      if (modifiedPNR.includes(sign)) {
        modifiedPNR = modifiedPNR
          .split(sign)
          .map((x) => {
            let y = "";

            for (const d of delimiters) {
              const p = new RegExp(d, "g");
              y = x.replace(p, "");
            }

            return y;
          })
          .filter((x) => x.trim())
          .join("-");
      }
    }

    sendEvent("SEARCH_BY_PNR", { token, pnr: modifiedPNR });
  };

  const onFinishFailed = (errorInfo: ValidationError<FormValues>) => {
    console.log("Form Failed:", errorInfo);
  };

  const token = currentState?.context?.token;

  const validationMessage =
    "Booking ID (PNR) must be more than 4 characters long and contains A-Z, 0-9 or hyphen (-)";

  return (
    <Row className="search-by-pnr-row">
      <Col>
        <Card
          title={
            <span>
              <TagTwoTone twoToneColor={green.primary} />
              <span style={{ marginLeft: "10px" }}>
                Search by Booking ID (PNR)
              </span>
            </span>
          }
          className="search-by-pnr-card"
        >
          <Form
            name="search-by-pnr"
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="token"
              initialValue={token}
              style={{ display: "none" }}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="pnr"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: validationMessage,
                },
                {
                  validateTrigger: "onSubmit",
                  validator: async (_, value) => {
                    if (/[a-zA-Z0-9-]{5,}/gi.test(value) === false) {
                      throw new Error();
                    }
                  },
                  transform: (value) => value.trim(),
                  message: validationMessage,
                },
              ]}
              validateFirst
            >
              <Input.Search
                placeholder="Enter PNR here..."
                value=""
                onSearch={() => {
                  form.submit();
                }}
                required
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

interface FormValues {
  token: string;
  pnr: string;
}
