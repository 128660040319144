import React from "react";
import { Button, Input, Form, Spin } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Props, ValidationError } from "../../typings";

export default ({ currentState, sendEvent }: Props): React.ReactElement => {
  const { token } = currentState.context;

  const onFinish = (values: FormValues) => {
    sendEvent("LOGIN_ATTEMPT", values);
  };

  const onFinishFailed = (errorInfo: ValidationError<FormValues>) => {
    console.log("Form Failed:", errorInfo);
  };

  return (
    <>
      {!token &&
        (currentState.matches("AppStarted") ||
          currentState.matches("LoginFailed")) && (
          <Form
            name="login"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email_address"
              rules={[
                {
                  required: true,
                  message: "Please provide the email address!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                value=""
                style={{ marginTop: "10px" }}
                placeholder="Email Address"
                type="email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please provide the password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                value=""
                style={{ marginTop: "5px" }}
                placeholder="Password"
                type="password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                style={{ marginTop: "5px" }}
                size="large"
                shape="round"
                type="primary"
                htmlType="submit"
                block
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        )}

      {currentState.matches("AttemptingLogin") && <Spin size="large" />}
    </>
  );
};

export interface FormValues {
  email_address: string;
  password: string;
}
