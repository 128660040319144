import { AnyEventObject } from "xstate";
import { AppContext } from "../../typings";

const login = async (
  _context: AppContext,
  event: AnyEventObject,
): Promise<string | Error> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const body = JSON.stringify({ ...event });

  const options = {
    method: "POST",
    headers: headers,
    body,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/login`,
      options,
    ).then((r) => r.json());

    if (typeof response.token === "string") {
      return response.token;
    }

    throw response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export default login;
