import { AppContext, Partial } from "../../typings";

export default (context: Partial<AppContext>): void => {
  // don't save booking details so that it can refresh
  context.bookingDetails = undefined;
  context.refund = undefined;
  context.refunds = undefined;

  try {
    localStorage.setItem("app-context", JSON.stringify(context));
  } catch (error) {
    console.error(error);
  }
};
