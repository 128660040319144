import React from "react";
import { Card, Col, Form, Input, Row } from "antd";
import { IdcardTwoTone } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

import { Props, ValidationError } from "../../typings";

export default ({ currentState, sendEvent }: Props): React.ReactElement => {
  const [form] = Form.useForm();

  const onFinish = (values: FormValues) => {
    sendEvent("SEARCH_CUSTOMER", values);
  };

  const onFinishFailed = (errorInfo: ValidationError<FormValues>) => {
    console.log("Form Failed:", errorInfo);
  };

  const token = currentState?.context?.token;

  const validationMessage =
    "Please enter a valid name, email address or phone number";

  return (
    <Row className="search-customer-row">
      <Col>
        <Card
          title={
            <span>
              <IdcardTwoTone twoToneColor={blue.primary} />
              <span style={{ marginLeft: "10px" }}>
                Search for Tripovy Customer
              </span>
            </span>
          }
          className="search-customer-card"
        >
          <Form
            name="search-customer"
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="token"
              initialValue={token}
              style={{ display: "none" }}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="query"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: validationMessage,
                },
                {
                  validator: async (_, value: string) => {
                    if (/.+/g.test(value.trim()) === false) {
                      throw new Error();
                    }
                  },
                  validateTrigger: "onSubmit",
                  message: validationMessage,
                },
              ]}
              validateFirst
            >
              <Input.Search
                placeholder="Name, email address or phone..."
                value=""
                onSearch={() => {
                  form.submit();
                }}
                required
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

interface FormValues {
  token: string;
  pnr: string;
}
