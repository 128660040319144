import { AppContext } from "../../typings";

const getRefundsByPNR = async (
  context: AppContext,
): Promise<string | Error> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const body = JSON.stringify({
    token: context.token,
    pnr: context.bookingDetails.pnr,
  });

  const options = {
    method: "POST",
    headers: headers,
    body,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/search-refund`,
      options,
    )
      .then((r) => r.json())
      .catch((e) => {
        console.warn(e);
        return null;
      });

    if (response?.refunds_originated instanceof Array) {
      return response.refunds_originated;
    }

    throw response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export default getRefundsByPNR;
