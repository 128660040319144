import { Card, Col, Divider, Row } from "antd";
import React, { ReactElement } from "react";
import * as dateFns from "date-fns";
import FlightDetails from "../FlightDetails";
import { AppContext, BookingDetails } from "../../typings";
import sampleBookingDetailsMulticityInternational from "../../typings/sample-booking-details-multicity-international";
import { parseRefundProcessorAPIResponse } from "./utils";

export const TicketCard = ({
  ticket: t,
}: {
  ticket: BookingDetails["tickets"][0];
}): ReactElement => (
  <Card title={t.name} className="ticket-card">
    <div>
      <span>Type: </span>
      <span>{t.type.toUpperCase()}</span>
    </div>
    <div>
      <span>Ticket Number: </span>
      <b>{t.ticket_number}</b>
    </div>
    {t.email && (
      <div>
        <span>Email Address: </span>
        <span>{t.email}</span>
      </div>
    )}
    {t.phone && (
      <div>
        <span>Phone: </span>
        <span>{t.phone}</span>
      </div>
    )}
  </Card>
);

interface Props {
  bookingDetails: BookingDetails;
  refunds: AppContext["refunds"];
}

const BookingDetailsComponent = ({
  bookingDetails,
  refunds,
}: Props): ReactElement => {
  const data = bookingDetails;

  const cabinClass = Object.keys(data.flight_details.price_itinerary)[0];

  const priceItinerary = (
    data.flight_details.price_itinerary as {
      [key: string]: typeof data.flight_details.price_itinerary.economy;
    }
  )[cabinClass];

  return (
    <div className="booking-details-container">
      <Row justify="center">
        <Col>
          <Card title="🚦 Booking Status">
            <div>
              <span>Booking ID (PNR): </span>
              <b>{data.pnr}</b>
            </div>
            <div>
              <span>Ticket Issued: </span>
              <span>{(data.is_issued && "Yes") || "No"}</span>
            </div>
            <div>
              <span>Payment Done: </span>
              <span>{(data.paid && "Yes") || "No"}</span>
            </div>

            <div>
              <span>Refunded: </span>
              <span>
                {(refunds &&
                  refunds[0] &&
                  refunds[0].status === "success" &&
                  refunds[0].refunded && <b>Yes</b>) ||
                  "No"}
              </span>
            </div>

            {!data.is_issued && (
              <>
                <div>
                  <span>Booking Expired: </span>
                  <span>{(data.is_expired && "Yes") || "No"}</span>
                </div>
                <div>
                  <span>Expiry Date-Time: </span>
                  <span>{dateFns.format(data.expiry_timestamp, "PPpp")}</span>
                </div>
              </>
            )}

            <div>
              <span>Created At: </span>
              <span>
                {dateFns.format(dateFns.parseISO(data.created_at.date), "PPpp")}
              </span>
            </div>
            <div>
              <span>Updated At: </span>
              <span>
                {dateFns.format(dateFns.parseISO(data.updated_at.date), "PPpp")}
              </span>
            </div>
          </Card>
        </Col>

        {refunds && (
          <Col>
            <Card title="↩️ Refund Information">
              {refunds.map((r, i) => (
                <div key={Math.random()}>
                  {i !== 0 && <Divider />}
                  <div>
                    <span>{i + 1}. Refunded Amount: </span>
                    <b>{r.refundable_amount.toLocaleString()}</b>
                  </div>
                  <div>
                    <span>Refund Charge: </span>
                    <b>{r.charge.toLocaleString()}</b>
                  </div>
                  <div>
                    <span>Refunded At: </span>
                    <span>
                      {r?.refunded_on &&
                        dateFns.format(dateFns.parseISO(r.refunded_on), "PPpp")}
                    </span>
                  </div>
                  <div>
                    <span>Refund Status: </span>
                    <b>{r.status}</b>
                  </div>

                  {parseRefundProcessorAPIResponse(r.response_json, i) && (
                    <div>
                      <div>
                        <span>Bank Transcation ID: </span>
                        <span>
                          {
                            parseRefundProcessorAPIResponse(r.response_json, i)
                              ?.bankTransId
                          }
                        </span>
                      </div>
                      <div>
                        <span>Refund Reference ID: </span>
                        <span>
                          {
                            parseRefundProcessorAPIResponse(r.response_json, i)
                              ?.refundRefId
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </Card>
          </Col>
        )}

        <Col>
          <Card title="👤 Ticket Issuer (User Account)">
            <div>
              <span>Name: </span>
              <b>{data.issuer?.name}</b>
            </div>
            <div>
              <span>Phone: </span>
              <span>{data.issuer?.phone || "N/A"}</span>
            </div>
            <div>
              <span>Email Address: </span>
              <span>{data.issuer?.email || "N/A"}</span>
            </div>
          </Card>
        </Col>

        <Col>
          <Card title="Booking Type">
            <div>
              <span>Trip Type: </span>
              <b>{data.trip_type.toUpperCase()}</b>
            </div>
            <div>
              <span>PNR Type: </span>
              <span>{data.pnr_type}</span>
            </div>
            {data.flight_details && (
              <div>
                <span>Refundable: </span>
                <span>
                  {(data.flight_details.is_refundable === true && "Yes") ||
                    "No"}
                </span>
              </div>
            )}
          </Card>
        </Col>

        {data.tickets && data.tickets.length > 0 && (
          <Col>
            <Card title="🎟️ Tickets">
              <Row>
                {(data.trip_type === "multicity" &&
                  (() => {
                    const tripWiseTickets: typeof data.tickets[] = [];

                    for (const ticket of data.tickets) {
                      if (typeof ticket.flight_index === "number") {
                        tripWiseTickets[ticket.flight_index] = [];
                        tripWiseTickets[ticket.flight_index].push(ticket);
                      }
                    }

                    // console.log({ tripWiseTickets });

                    return (
                      <>
                        {tripWiseTickets.map((group, i) => {
                          return (
                            <Card
                              title={`Trip ${i + 1} (${
                                data.pnr.split("-")[i]
                              })`}
                              key={Math.random()}
                            >
                              {group.map((ticket) => (
                                <Col key={Math.random()}>
                                  <TicketCard ticket={ticket} />
                                </Col>
                              ))}
                            </Card>
                          );
                        })}
                      </>
                    );
                  })()) ||
                  data.tickets?.map((t) => {
                    return (
                      <Col key={Math.random() * 10000}>
                        <TicketCard ticket={t} />
                      </Col>
                    );
                  })}
              </Row>
            </Card>
          </Col>
        )}

        {priceItinerary && (
          <Col>
            <Card title="Price Itinerary">
              <>
                {Object.entries(priceItinerary.fare_breakdown).map(
                  (priceData, i) => {
                    const key = priceData[0];
                    const value = priceData[1];

                    if (typeof value === "number") {
                      return (
                        <Row
                          key={i}
                          style={{
                            display: key.includes("discounted")
                              ? "none"
                              : "inherit",
                          }}
                        >
                          <Col>
                            <span>
                              {key.replace(/\_/gi, " ").toUpperCase()}:
                            </span>{" "}
                          </Col>
                          <Col>
                            <b>
                              {value.toLocaleString("en-IN")}{" "}
                              {priceItinerary.currency}
                            </b>
                          </Col>
                        </Row>
                      );
                    }

                    if (!value || value.price === 0) {
                      return <div key={i}></div>;
                    }

                    const passengerType = key;

                    return (
                      <Row key={i}>
                        <Col style={{ width: "500px" }}>
                          <Row style={{ width: "100%" }}>
                            <Col>
                              <div>{passengerType.toUpperCase()}:</div>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <b>
                                {(value.price - value.tax).toLocaleString(
                                  "en-IN",
                                )}{" "}
                                {priceItinerary.currency}
                              </b>
                              <span> (Base Fare) + </span>
                            </Col>
                            <Col>
                              <b>
                                {value.tax.toLocaleString("en-IN")}{" "}
                                {priceItinerary.currency}
                              </b>
                              <span> (Tax)</span>
                            </Col>
                            <Col>
                              <span> = </span>
                              <b>
                                {value.price.toLocaleString("en-IN")}{" "}
                                {priceItinerary.currency}
                              </b>
                            </Col>
                          </Row>
                          {value.quantity > 1 && (
                            <Row>
                              <Col>
                                <b>x {value.quantity}</b>
                                <span> passengers</span>
                              </Col>
                              <Col>
                                <span> = </span>
                                <b>
                                  {(
                                    value.price * value.quantity
                                  ).toLocaleString("en-IN")}{" "}
                                  {priceItinerary.currency}
                                </b>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    );
                  },
                )}
              </>
            </Card>
          </Col>
        )}

        {data.flight_details && (
          <Col span={22}>
            <Card
              title={`✈️ ${
                (data.flight_details.type === "oneway" && "Oneway") || ""
              } ${
                (data.flight_details.type === "roundtrip" && "Roundtrip") || ""
              } Flight Details ${
                data.flight_details.type === "multicity"
                  ? `of ${
                      (
                        data.flight_details as typeof sampleBookingDetailsMulticityInternational["flight_details"]
                      )?.trips?.length
                    } Trips (Multi-city)`
                  : ""
              }`}
            >
              <Row>
                <Col>
                  <Card>
                    Passport Required:{" "}
                    {data.flight_details.passport_required === true
                      ? "Yes"
                      : "No"}
                  </Card>
                </Col>
              </Row>

              <FlightDetails
                flightDetails={data.flight_details}
                pnr={data.pnr}
              />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BookingDetailsComponent;
