import {
  AnyEventObject,
  assign,
  createMachine,
  StateSchema,
  StatesConfig,
} from "xstate";
import { AppContext } from "../../typings";
import login from "../services/login";
import getDefaultAppContext from "../utils/get-default-app-context";
import saveAppContext from "../utils/save-app-context";
import searchByPNR from "../services/search-by-pnr";
import searchCustomer from "../services/search-customer";
import getBookingsByCustomer from "../services/get-bookings-by-customer";
import getRefundsByPNR from "../services/get-refunds-by-pnr";

const persistedStates: string[] = [];

const searchByPNRService = {
  id: "search-by-pnr",
  src: searchByPNR,
  onDone: {
    target: "FetchingRefundsByPNR",
    actions: assign((context: AppContext, event: AnyEventObject) => {
      const data = { bookingDetails: event.data };

      if (event.data?.pnr) {
        context.pnr = event.data.pnr;
      }

      saveAppContext({ ...context, ...data });

      return data;
    }),
  },
  onError: {
    target: "AppStarted",
  },
};

const getRefundsByPNRService = {
  id: "get-refunds-by-pnr",
  src: getRefundsByPNR,
  onDone: {
    target: "BookingDetailsShown",
    actions: assign((context: AppContext, event: AnyEventObject) => {
      const data = { refunds: event.data };

      saveAppContext({ ...context, ...data });

      return data;
    }),
  },
  onError: {
    target: "AppStarted",
  },
};

const getRefundsByPNRFromCustomerDetailsService = {
  ...getRefundsByPNRService,
  onDone: {
    target: "BookingByCustomerDetailsShown",
    actions: assign((context: AppContext, event: AnyEventObject) => {
      const data = { refunds: event.data };

      saveAppContext({ ...context, ...data });

      return data;
    }),
  },
};

const searchByPNRServiceForCustomer = {
  ...searchByPNRService,
  onDone: {
    target: "FetchingRefundsByPNRFromCustomerDetails",
    actions: assign((context: AppContext, event: AnyEventObject) => {
      const data = { bookingDetails: event.data };

      if (event.data?.pnr) {
        context.pnr = event.data.pnr;
      }

      saveAppContext({ ...context, ...data });

      return data;
    }),
  },
};

const states: StatesConfig<AppContext, StateSchema, AnyEventObject> = {
  AppStarted: {
    on: {
      LOGIN_ATTEMPT: {
        target: "AttemptingLogin",
      },
      SEARCH_BY_PNR: {
        target: "SearchingByPNR",
      },
      GO_TO_BOOKING_DETAILS: {
        target: "BookingDetailsShown",
      },
      GO_TO_CUSTOMER_BOOKING_DETAILS: {
        target: "BookingByCustomerDetailsShown",
      },
      SEARCH_CUSTOMER: {
        target: "SearchingCustomer",
      },
      VIEW_CUSTOMER_DETAILS: {
        target: "CustomerDetailsShown",
      },
    },
  },

  AttemptingLogin: {
    invoke: {
      id: "login",
      src: login,
      onDone: {
        target: "AppStarted",
        actions: assign((_context, event: AnyEventObject) => {
          const data = { token: event.data };

          saveAppContext(data);

          return data;
        }),
      },
      onError: {
        target: "AppStarted",
      },
    },
  },

  SearchingByPNR: {
    invoke: searchByPNRService,
  },

  FetchingRefundsByPNR: {
    invoke: getRefundsByPNRService,
  },

  SearchingByPNRForCustomer: {
    invoke: searchByPNRServiceForCustomer,
  },

  FetchingRefundsByPNRFromCustomerDetails: {
    invoke: getRefundsByPNRFromCustomerDetailsService,
  },

  BookingDetailsShown: {},

  BookingByCustomerDetailsShown: {
    on: {
      GO_BACK_TO_LIST: {
        target: "BookingsByCustomerShown",
        actions: assign((context: AppContext) => {
          context.pnr = undefined;

          const newCtx = { ...context, bookingDetails: undefined };

          saveAppContext(newCtx);

          return newCtx;
        }),
      },
    },
  },

  SearchingCustomer: {
    invoke: {
      id: "search-customer",
      src: searchCustomer,
      onDone: {
        target: "AppStarted",
        actions: assign((_context: AppContext, event: AnyEventObject) => {
          const data = { customers: event.data };

          return data;
        }),
      },
      onError: {
        target: "AppStarted",
      },
    },
  },

  CustomerDetailsShown: {
    entry: [
      assign((_context: AppContext, event: AnyEventObject) => {
        if (event.type === "VIEW_CUSTOMER_DETAILS") {
          const data = { customer: event.customer };

          return data;
        }

        return {};
      }),
    ],
    invoke: {
      id: "get-bookings-by-customer",
      src: getBookingsByCustomer,
      onDone: {
        target: "BookingsByCustomerShown",
        actions: assign((context: AppContext, event: AnyEventObject) => {
          const data = { bookingsByCustomer: event.data };

          saveAppContext({ ...context, ...data });

          return data;
        }),
      },
      onError: {
        target: "BookingsByCustomerShown",
      },
    },
  },

  BookingsByCustomerShown: {
    on: {
      SEARCH_BY_PNR_FOR_CUSTOMER: {
        target: "SearchingByPNRForCustomer",
        actions: assign(() => {}),
      },
    },
  },
};

let previousState: string | null = null;

previousState = JSON.parse(localStorage.getItem("app-state") ?? "null");

const loadIntialState = (): string => previousState ?? "AppStarted";

let initialState = loadIntialState();

if (!persistedStates.includes(initialState)) {
  initialState = "AppStarted";
}

const appMachine = createMachine<AppContext>(
  {
    id: "app",
    initial: initialState,
    states,
    on: {
      LOGOUT: {
        target: "AppStarted",
        actions: [
          assign<AppContext>((context) => {
            for (const key in context) {
              if (key) {
                context[key] = null;
              }
            }

            return context;
          }),
          () => {
            localStorage.removeItem("app-context");
          },
        ],
      },
      GO_HOME: {
        target: "AppStarted",
      },
      GO_BACK: {
        target: "AppStarted",
        actions: assign((context: AppContext, event: AnyEventObject) => {
          for (const key in context) {
            if (key !== "token" && key !== event.preserveContextKey) {
              context[key] = undefined;
            }
          }

          saveAppContext(context);

          return context;
        }),
      },
    },
    context: getDefaultAppContext(),
  },
  {
    actions: {},
    services: {},
  },
);

export default appMachine;
