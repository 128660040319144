import React from "react";
import * as dateFns from "date-fns";
import { Alert, Card, Col, Row, Spin } from "antd";
import { green, red } from "@ant-design/colors";
import { Props } from "../../typings";

export default ({ currentState, sendEvent }: Props): React.ReactElement => {
  const { context } = currentState;

  const { customer, bookingsByCustomer, token } = context;

  let walletAmount: number | string = customer.wallet;

  try {
    walletAmount = walletAmount.toLocaleString();
  } catch (error) {
    console.error(error);
  }

  return (
    <>
      <Card title="Customer's Details">
        <Row>
          <Col>
            <Card>
              Name:{" "}
              <b>{`${customer.profile.title} ${customer.profile.name}`}</b>
            </Card>
            <Card>
              Email Address: <b>{`${customer.profile.email}`}</b>
            </Card>
            <Card>Phone Number: {`${customer.profile.phone || "N/A"}`}</Card>
          </Col>

          <Col>
            <Card>
              Wallet: <b>{`${walletAmount}`}</b>
            </Card>
          </Col>
        </Row>
      </Card>

      {(currentState.event.data && currentState.event.data.message && (
        <div>
          <Alert
            message={currentState.event.data && currentState.event.data.message}
            type="error"
            closable
          />
        </div>
      )) || (
        <Card
          title="Customer's Bookings"
          style={{
            marginTop: "20px",
            width: "700px",
            maxWidth: "96vw",
            borderColor: "transparent",
          }}
        >
          {!bookingsByCustomer && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin style={{ margin: "20px" }} size="large" />
            </div>
          )}

          {bookingsByCustomer &&
            bookingsByCustomer.map((booking) => {
              return (
                <Card
                  key={booking.pnr}
                  className="booking-by-customer"
                  title={
                    <div>
                      PNR: {booking.pnr} (
                      {booking.is_issued === 1 && (
                        <span style={{ color: green.primary }}>ISSUED</span>
                      )}
                      {booking.is_expired === true && (
                        <span style={{ color: red.primary }}>EXPIRED</span>
                      )}
                      )
                    </div>
                  }
                  onClick={(event) => {
                    event.preventDefault();

                    return sendEvent("SEARCH_BY_PNR_FOR_CUSTOMER", {
                      token,
                      pnr: booking.pnr,
                    });
                  }}
                >
                  <Row>
                    <Col>
                      <div>
                        <span>Created At: </span>
                        <span>
                          {dateFns.format(
                            dateFns.parseISO(booking.created_at.date),
                            "PPpp",
                          )}
                          ,
                        </span>
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <span>Updated At: </span>
                        <span>
                          {dateFns.format(
                            dateFns.parseISO(booking.updated_at.date),
                            "PPpp",
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
        </Card>
      )}
    </>
  );
};
