import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { Alert, Button, Card, Col, Divider, PageHeader, Row, Spin } from "antd";
import { blue, orange } from "@ant-design/colors";
import { LogoutOutlined, RollbackOutlined } from "@ant-design/icons";

import appMachine from "../../statecharts/machines/app-machine";
import "./App.css";
import LoginForm from "../LoginForm";
import SearchByPNR from "../SearchByPNR";
import BookingDetails from "../BookingDetails";
import SearchCustomer from "../SearchCustomer";
import CustomerDetails from "../CustomerDetails/CustomerDetails";

const App = (): React.ReactElement => {
  const [currentState, sendEvent] = useMachine(appMachine);

  const { context } = currentState;

  const {
    token,
    bookingDetails,
    customers,
    customer,
    bookingsByCustomer,
    refunds,
    pnr,
  } = context;

  let canGoBack = false;

  let preserveContextKey: string;

  if (
    (currentState?.event?.type === "VIEW_CUSTOMER_DETAILS" ||
      currentState.matches("BookingsByCustomerShown")) &&
    customers &&
    customers.length > 0
  ) {
    preserveContextKey = "customers";
  }

  for (const key in context) {
    if (
      key !== "token" &&
      typeof context[key] !== "undefined" &&
      context[key] !== null
    ) {
      canGoBack = true;
    }
  }

  const loading =
    currentState.matches("SearchingByPNR") ||
    currentState.matches("SearchingCustomer") ||
    currentState.matches("SearchingByPNRForCustomer") ||
    currentState.matches("SearchingCustomer") ||
    currentState.matches("FetchingRefundsByPNR");

  console.log("currentState.value", currentState.value);

  useEffect(() => {
    if (
      bookingDetails &&
      !currentState.matches("BookingDetailsShown") &&
      !currentState.matches("BookingByCustomerDetailsShown")
    ) {
      if (customer && bookingsByCustomer) {
        sendEvent("GO_TO_CUSTOMER_BOOKING_DETAILS");
      }

      sendEvent("GO_TO_BOOKING_DETAILS");
    }
  }, [bookingDetails, bookingsByCustomer, currentState, customer, sendEvent]);

  useEffect(() => {
    if (
      pnr &&
      !bookingDetails &&
      !currentState.matches("BookingDetailsShown") &&
      !currentState.matches("BookingByCustomerDetailsShown")
    ) {
      sendEvent("SEARCH_BY_PNR", { token, pnr });
    }
  }, [token, pnr, bookingDetails, currentState, sendEvent]);

  return (
    <div className="app">
      <PageHeader title="Tripovy Customer Care" />

      <LoginForm currentState={currentState} sendEvent={sendEvent} />

      {token && currentState.matches("AppStarted") && (
        <Row justify="center">
          <Col>
            <SearchByPNR currentState={currentState} sendEvent={sendEvent} />
          </Col>

          <Col>
            <SearchCustomer currentState={currentState} sendEvent={sendEvent} />
          </Col>
        </Row>
      )}

      {currentState.matches("AppStarted") &&
        currentState.event.data &&
        currentState.event.data.message && (
          <div>
            <Alert
              message={
                currentState.event.data && currentState.event.data.message
              }
              type="error"
              closable
            />
          </div>
        )}

      {loading && <Spin size="large" />}

      {customer &&
        (currentState.matches("CustomerDetailsShown") ||
          currentState.matches("BookingsByCustomerShown")) && (
          <CustomerDetails currentState={currentState} sendEvent={sendEvent} />
        )}

      {(currentState.matches("BookingDetailsShown") ||
        currentState.matches("BookingByCustomerDetailsShown")) &&
        bookingDetails && (
          <BookingDetails bookingDetails={bookingDetails} refunds={refunds} />
        )}

      {customers &&
        currentState.matches("AppStarted") &&
        !loading &&
        customers.length && (
          <div>
            <Divider />

            <Row justify="center" style={{ minHeight: "600px" }}>
              {customers.map((c) => (
                <Col
                  key={c.profile.email}
                  onClick={() => {
                    sendEvent("VIEW_CUSTOMER_DETAILS", { customer: c });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Card
                    title={
                      <span
                        style={{ color: blue.primary }}
                      >{`${c.profile.title} ${c.profile.name}`}</span>
                    }
                    className="customer-result-card"
                  >
                    <Card>
                      Email Address: <b>{`${c.profile.email}`}</b>
                    </Card>
                    <Card>Phone Number: {`${c.profile.phone || "N/A"}`}</Card>

                    <Button style={{ width: "100%" }} type="primary">
                      Show Details
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}

      {canGoBack && (
        <RollbackOutlined
          className="nav-item"
          title="Go Back"
          style={{
            fontSize: "40px",
            position: "fixed",
            bottom: "80px",
            right: "20px",
            color: blue.primary,
            animation: "fallDown 0.5s ease-out",
          }}
          onClick={(event) => {
            event.preventDefault();

            console.log("Back clicked");

            console.log(
              `currentState.matches("BookingByCustomerDetailsShown")`,
              currentState.matches("BookingByCustomerDetailsShown"),
            );

            if (currentState.matches("BookingByCustomerDetailsShown")) {
              return sendEvent("GO_BACK_TO_LIST");
            }

            return sendEvent("GO_BACK", { preserveContextKey });
          }}
        />
      )}

      {token && token.length > 0 && (
        <LogoutOutlined
          className="nav-item"
          title="Logout"
          style={{
            fontSize: "40px",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            color: orange.primary,
            animation: "fallDown 0.8s ease-out",
          }}
          onClick={(event) => {
            event.preventDefault();

            sendEvent("LOGOUT");
          }}
        />
      )}
    </div>
  );
};

export default App;
