import { RefundProcessorResponse } from "../../typings";
import sampleRefundProcessorApiResponse1 from "../../typings/sample-refund-processor-api-response-1";

interface RefundProcessorCombinedResponse {
  bankTransId: string;
  refundRefId: string;
}

export function parseRefundProcessorAPIResponse(
  data: string,
  index: number,
): RefundProcessorCombinedResponse | null {
  try {
    const parsed: RefundProcessorResponse = JSON.parse(data);

    if (parsed && typeof parsed[0] === "boolean") {
      return null;
    }

    const parsed1 = (parsed as typeof sampleRefundProcessorApiResponse1)[index];

    if (!parsed1) {
      return null;
    }

    return {
      bankTransId: parsed1.bank_tran_id,
      refundRefId: parsed1.refund_ref_id,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default {};
